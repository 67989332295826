import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useGesture } from '@use-gesture/react';
import { Pagination, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { motion, useAnimation } from 'framer-motion';
import { ClipLoader, BeatLoader, SyncLoader } from "react-spinners";
import Footer from '../Nav/footer';
import { Plus, Minus } from 'lucide-react';

let minScale = 1;
let maxScale = 3;
let initialScale = 2;
let currentPosition = null;

let RangeWidth = 420
let RangeHeight = 300

function Map() {
    const controls = useAnimation();
    const [dataReceivedCount, setDataReceivedCount] = useState(0);
    const [selectedFloor, setSelectedFloor] = useState('3F');
    const [tagName, setTagName] = useState("");
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
    const [imgLoading, setImgLoading] = useState(true);
    const [message, setMessage] = useState("해핑케어를 위한 정보를 불러오고 있습니다.");
    const [highlightedAreas, setHighlightedAreas] = useState([]); // 배경을 빨갛게 칠할 구역 ID 목록

    const floorAreas = {
        '3F': [
            { id: 1, startX: 5892.86, startY: 1210, endX: 6478.57, endY: 3060 },
        ],
        '4F': [
            { id: 1, startX: 7164.29, startY: 2070, endX: 8857.14, endY: 3700 },
        ],
        '5F': [
            { id: 1, startX: 7178.57, startY: 2100, endX: 8800, endY: 3760 },
        ]
    };

    // useEffect(() => {
    //     const handleOrientationChange = (e) => {
    //         setDataReceivedCount(0);
    //         setIsLandscape(e.matches);
    //     };

    //     const mediaQuery = window.matchMedia("(orientation: landscape)");
    //     mediaQuery.addEventListener("change", handleOrientationChange);

    //     return () => {
    //         mediaQuery.removeEventListener("change", handleOrientationChange);
    //     };
    // }, []);

    useEffect(() => {
        setTimeout(() => {
            setImgLoading(false);
        }, 3000);
    }, []);

    const changeFloor = () => {
        setImgLoading(true)
        setTimeout(() => {
            setImgLoading(false);
        }, 2000);
    }

    const [{ x, y, scale }, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: initialScale,
    }));

    const bind = useGesture({
        onDrag: ({ offset: [x, y] }) => {
            api.start({ x, y, immediate: true });
        },
        onPinch: ({ offset: [d] }) => {
            api.start({ scale: d, immediate: true });
        },
        onWheel: ({ movement: [_, scrollY] }) => {
            const newScale = scale.get() - scrollY * 0.001;
            api.start({ scale: Math.max(minScale, Math.min(maxScale, newScale)), immediate: true });
        }
    }, {
        drag: { from: () => [x.get(), y.get()] },
        pinch: { scaleBounds: { min: minScale, max: maxScale } }
    });

    const handleZoomIn = () => {
        api.start({ scale: Math.min(scale.get() + 0.5, maxScale) });
    };

    const handleZoomOut = () => {
        api.start({ scale: Math.max(scale.get() - 0.5, minScale) });
    };

    const handleReset = () => {
        api.start({ scale: 2 });
    };

    useEffect(() => {
	// const serverUrl = process.env.REACT_APP_SERVER_URL || 'ws://192.168.0.116:8080';
	        const serverUrl = process.env.REACT_APP_SERVER_URL || 'wss://1.214.214.10:39980';

        const socket = new WebSocket(serverUrl);
        let initial_Floor = -1;

        socket.onopen = () => {
            const tag = 'A0001';
            socket.send(JSON.stringify({ tag }));
        };

        socket.onmessage = (event) => {
            const response = JSON.parse(event.data);
            if (!response.error) {
                const { x: newX, y: newY, tag: tag, f: floor } = response.data;

                if (newX !== undefined && newY !== undefined) {
                    const computedX = (newX / 10000) * RangeWidth;
                    const computedY = (newY / 10000) * RangeHeight;

                    setDataReceivedCount((prevCount) => prevCount + 1);
                    setTagName(tag);

                    console.log(floor)

                    if (initial_Floor !== floor) {
                        if (initial_Floor !== -1) setMessage(`다른층으로 이동중입니다.`);
                        setSelectedFloor(`${floor}F`);
                        initial_Floor = floor;
                        changeFloor()
                    }

                    const deltaX = currentPosition ? Math.abs(computedX - currentPosition.x) : 0;
                    const deltaY = currentPosition ? Math.abs(computedY - currentPosition.y) : 0;

                    if (deltaX > 100 || deltaY > 100) {
                        currentPosition = { x: computedX, y: computedY };
                        controls.set({ x: computedX, y: computedY });
                    } else {
                        controls.start({
                            x: computedX,
                            y: computedY,
                            transition: {
                                duration: 1,
                                ease: 'linear'
                            }
                        });
                    }

                    // 현재 좌표가 구역 내에 있는지 확인하고, 들어가 있으면 배경을 빨갛게 설정
                    const activeAreas = floorAreas[`${floor}F`].filter(area =>
                        computedX >= (area.startX / 10000) * RangeWidth &&
                        computedX <= (area.endX / 10000) * RangeWidth &&
                        computedY >= (area.startY / 10000) * RangeHeight &&
                        computedY <= (area.endY / 10000) * RangeHeight
                    ).map(area => area.id);

                    focusOnCoordinates();
                    currentPosition = { x: computedX, y: computedY };

                    setHighlightedAreas(activeAreas);
                }
            }
        };

        socket.onclose = () => {
            console.log('Disconnected from WebSocket server');
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => {
            socket.close();
        };
    }, []);

    const aspectRatio = RangeHeight / RangeWidth;
    const height = `calc(${RangeWidth}px * ${aspectRatio})`;

    const [imageStyle, setImageStyle] = useState({
        width: 'auto',
        height: 'auto',
    });

    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;

        if (naturalWidth > naturalHeight) {
            setImageStyle({ width: '90%', height: 'auto' });
        } else {
            setImageStyle({ width: 'auto', height: '90%' });
        }
    };

    const focusOnCoordinates = () => {
        if (currentPosition) {
            const computedX = currentPosition.x - RangeWidth / 2;
            const computedY = currentPosition.y - RangeHeight / 2;

            api.start({
                x: -computedX * scale.get(),
                y: -computedY * scale.get(),
                config: { duration: 1000 }
            });
        }
    };

    return (
        <div style={{ overflow: 'hidden', width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <animated.div
                {...bind()}
                style={{
                    x,
                    y,
                    scale,
                    touchAction: 'none',
                    width: `${RangeWidth}px`,
                    height: `${RangeHeight}px`,
                    position: "relative"
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: height,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <img
                        src={`/img/${selectedFloor === "3F" ? '평면도' : selectedFloor === "4F" ? '4F' : '5F'}.png`}
                        alt='평면도'
                        onLoad={handleImageLoad}
                        className='design_img'
                        style={{ ...imageStyle }}
                    />
                    {floorAreas[selectedFloor].map(area => (
                        <div
                            key={area.id}
                            style={{
                                position: 'absolute',
                                left: (area.startX / 10000) * RangeWidth,
                                top: (area.startY / 10000) * RangeHeight,
                                width: ((area.endX - area.startX) / 10000) * RangeWidth,
                                height: ((area.endY - area.startY) / 10000) * RangeHeight,
                                backgroundColor: highlightedAreas.includes(area.id) ? 'rgba(255, 0, 0, 0.5)' : 'transparent',
                                pointerEvents: 'none',
                            }}
                        />
                    ))}
                    <motion.div
                        animate={controls}
                        initial={false}
                        className='animated-circle'
                        style={{
                            position: "absolute",
                            display: dataReceivedCount >= 2 ? "" : "none"
                        }}
                    >
                        <div className='animated-tag'
                        >{tagName}</div>
                    </motion.div>
                </div>
            </animated.div>
            <FloorContainer selectedFloor={selectedFloor} setSelectedFloor={setSelectedFloor} setDataReceivedCount={setDataReceivedCount} />
            <ScaleContainer onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onReset={handleReset} />
            {
                imgLoading ?
                    <div className='map-splash'>
                        <img src='/img/care-img.png' className='spinner-img' />
                        <div className='splash'>
                            <SyncLoader
                                color="#003577"
                                size={15}
                                cssOverride={{ borderWidth: "4px", marginBottom: "30px" }}
                                speedMultiplier={0.8}
                            />
                            <div className='spinner-message'>{message}</div>
                        </div>
                        <Footer />
                    </div>
                    :
                    ""
            }
        </div>
    );
}

function ScaleContainer({ onZoomIn, onZoomOut, onReset }) {
    return (
        <div className='scale-container'>
            <div className='scale-container-item' onClick={onZoomIn}><Plus /></div>
            <div className='scale-container-item' onClick={onReset}>기본</div>
            <div className='scale-container-item' onClick={onZoomOut}><Minus /></div>
        </div>
    );
}

function FloorContainer({ selectedFloor, setSelectedFloor, setDataReceivedCount }) {
    const floors = ['3F', '4F', '5F'];
    const [activeIndex, setActiveIndex] = useState(floors.indexOf(selectedFloor));
    const swiperRef = useRef(null);

    const handleFloorSelect = (floor, index) => {
        setDataReceivedCount(0);
        setSelectedFloor(floor);
        setActiveIndex(index);
        if (swiperRef.current) {
            swiperRef.current.slideTo(index);
        }
    };

    return (
        <div className="floor-container">
            <Swiper
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                direction="vertical"
                slidesPerView={6}
                centeredSlides={true}
                centeredSlidesBounds={true}
                slideToClickedSlide={true}
                height={320}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
                onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
                {floors.reverse().map((floor, index) => (
                    <SwiperSlide
                        key={floor}
                        className={`floor-item ${selectedFloor === floor ? 'selected' : ''} ${index === activeIndex ? 'swiper-slide-active' : ''}`}
                        style={{ height: "auto" }}
                    >
                        {floor}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default Map;
