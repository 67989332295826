import { useEffect, useState } from 'react';
import './nav.css'
import { Menu } from 'lucide-react';
import Hamburger from 'hamburger-react'
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';

function Header() {
    let navigate = useNavigate()
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div className='header'>
                <div className='header-box'>
                    {/* <img src='/img/logo_light.png' style={{ width: "130px", paddingTop: "4px", paddingLeft: '15px' }} onClick={() => navigate('/')} /> */}
                    <div className='header-box-icon'>
                        <Hamburger toggled={isOpen} toggle={setOpen} size={20} />
                    </div>
                </div>
            </div>
            {
                isOpen
                    ?
                    <MenuList setOpen={setOpen} />
                    :
                    ""
            }
        </>
    )
}

function MenuList({ setOpen }) {
    let navigate = useNavigate()

    return (
        <div className='menu' data-aos="fade-left" data-aos-duration="500">
            <div className='menu-container'>
                <div className='menu-container-item' onClick={() => { navigate('/main'); setOpen(false) }}>홈</div>
                <div className='menu-container-item' onClick={() => { navigate('/paymentHistory'); setOpen(false) }}>결제내역</div>
                <div className='menu-container-item' onClick={() => { navigate('/setting'); setOpen(false) }}>설정</div>
            </div>
        </div>
    )
}

export default Header;