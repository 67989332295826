import './App.css';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { ClipLoader } from "react-spinners";

import Header from './Nav/header';
import Footer from './Nav/footer';
import Login from './Pages/login';
import Main from './Pages/main';
import Map from './Pages/map';
import PaymentHistory from './Pages/paymentHistory';
import Setting from './Pages/setting';
import Alarm from './Pages/alarm';
import Payment from './Pages/payment';

function App() {
  const [loading, setLoading] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // 이미지 로딩 상태 추가
  const [loca, setLoca] = useState(true)
  let navigate = useNavigate()
  let location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      setLoading(1);
    }, 3000);
  }, []);

  const handleImageLoad = () => {
    setIsImageLoaded(true); // 이미지가 로드되면 상태를 업데이트합니다.
  };

  useEffect(() => {
    if (location) {
      if (location.pathname == '/') setLoca(false)
      else setLoca(true)
    }
  }, [location])

  // 페이지별 조건 설정
  const isBackgroundVisible = ['/', '/main', '/alarm'].includes(location.pathname);
  const backgroundOpacity = loading === 0 ? 1 : 0.5;

  console.log()
  return (
    <div className="App">
      <div
        className={`App-background animation`}
        style={{
          backgroundImage: isBackgroundVisible ? `url('/img/background_1.jpg')` : 'none',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          opacity: backgroundOpacity,
          width: '100vw',
          height: '100vh',
        }}>
      </div>
      {loading === 0 && isBackgroundVisible ? (
        <div className='main'>
          {/* <div className='splash'>
            <img src='/img/logo_light.png' className='main-logo-img' onLoad={handleImageLoad} style={{ pointerEvents: "none" }} />
            {isImageLoaded && (
              <div>
                <ClipLoader
                  color="#003577"
                  size={50}
                  cssOverride={{ borderWidth: "4px", marginBottom: "15px" }}
                  speedMultiplier={0.3}
                />
              </div>
            )}
          </div> */}
          <Footer />
        </div>
      ) : (
        <>
          {loca && (<Header />)}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/main" element={<Main />} />
            <Route path="/map" element={<Map />} />
            <Route path="/paymentHistory" element={<PaymentHistory />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/alarm" element={<Alarm />} />
            <Route path="/payment" element={<Payment />} />



            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
