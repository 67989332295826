import { useNavigate } from 'react-router-dom'
import './main.css'
import { useState } from 'react'


function Setting() {
    let navigate = useNavigate()

    const [darkmode, setDarkmode] = useState(false)

    const onSubmitLogout = () => {
        localStorage.removeItem('login')
        localStorage.removeItem('payment')
        navigate('/')
    }
    return (
        <div className='main'>
            <div className='main-container'>
                <div className='setting-header'>
                    <div style={{ marginBottom: "5px" }}>홍길동</div>
                    <div style={{ fontSize: "14px" }}>010-1234-1234</div>
                    <div className='setting-img'></div>
                </div>
                <div className='setting-body'>
                    <div className='setting-body-item'>
                        <div>테마변경</div>
                        <div className='menu-2-1-2'>
                            <div className={`toggle-box ${darkmode ? 'toggled' : ''}`} onClick={() => setDarkmode(e => !e)}>
                                {
                                    darkmode
                                        ?
                                        <span className={`toggle-box-on`}>다크</span>
                                        :
                                        <span className={`toggle-box-off`}>라이트</span>
                                }
                                <div className="toggle-box-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='setting-btn' onClick={() => onSubmitLogout()}>로그아웃</div>
            </div>
        </div>
    )
}

export default Setting;