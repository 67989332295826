import { configureStore, createSlice } from '@reduxjs/toolkit'

let login = createSlice({
    name: 'login',
    initialState: { value: false },
    reducers: {
        checkLogin(state, action) {
            state.value = action.payload
        }
    }
})

let MyInfo = createSlice({
    name: 'MyInfo',
    initialState: [],
    reducers: {
        checkMyInfo(state, action) {
            return action.payload
        }
    }
})


export let { checkLogin } = login.actions
export let { checkMyInfo } = MyInfo.actions

export default configureStore({
    reducer: {
        login: login.reducer,
        MyInfo: MyInfo.reducer
    }
}) 