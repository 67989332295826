import { useEffect, useState } from 'react';
import './main.css';
import { useNavigate } from 'react-router-dom';
import { CircleUserRound } from 'lucide-react';
import { SyncLoader } from "react-spinners";

function Main() {
    let navigate = useNavigate();
    const [splash, setSplash] = useState(false)

    const onClickPayment = () => {
        setSplash(true)
        localStorage.setItem('payment', true);

        setTimeout(() => {
            navigate('/payment')
        }, 1500)
    };

    return (
        <div className='main'>
            <div className='main-container'>
                <div className='main-container-box'>
                    <div className='main-container-box-1'>
                        <div className='main-box-1'>
                            <CircleUserRound size={50} />
                            <div>홍길동</div>
                        </div>
                        <div className='main-box-2'>
                            <div>병원: 양재요양원</div>
                            <div>주소: 서울특별시 강남구 강남대로 221</div>
                            <div>입소일: 2024.06.05</div>
                            {/* <div>마지막 결제: 2024.10.25</div> */}
                        </div>
                    </div>
                    <div className='login-btn' onClick={onClickPayment}>해핑케어 참여하기</div>
                </div>
            </div>
            {
                splash ?
                    <div className='splash'>
                        <SyncLoader
                            color="#003577"
                            size={15}
                            cssOverride={{ borderWidth: "4px", marginBottom: "30px" }}
                            speedMultiplier={0.8}
                        />
                    </div>
                    :
                    ""
            }
        </div>
    );
}

export default Main;
