import '@fortawesome/fontawesome-free/css/all.css';
import { useEffect, useState, useRef } from 'react';
import './modal.css';

function Message({ message }) {
    const [modal, setModal] = useState(false);
    const [currentMessage, setCurrentMessage] = useState({ code: 0, msg: "" });
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (message?.msg?.length > 0) {
            setCurrentMessage(message);
            onChangeModal();
        }
    }, [message]);

    const onChangeModal = () => {
        setModal(true);

        // 기존 타이머를 취소하고 새로운 타이머 설정
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setModal(false);
            timeoutRef.current = null; // 타이머 초기화
        }, 2000);
    };

    return (
        <div>
            {modal && (
                <div key={currentMessage.msg} className="buy-modal copy">
                    <div>
                        {currentMessage?.code === 0 ? (
                            <i className="fa-regular fa-circle-check" style={{ color: "#20B26C" }}></i>
                        ) : (
                            <i className="fa-regular fa-circle-xmark" style={{ color: "#EF454A" }}></i>
                        )}
                    </div>
                    <div>{currentMessage?.msg}</div>
                </div>
            )}
        </div>
    );
}

export default Message;
