import './main.css'


function PaymentHistory() {
    const arr = [
        { "date": "24-10-05", "amount": "3" },
        { "date": "24-09-05", "amount": "3" },
        { "date": "24-08-05", "amount": "3" },
        { "date": "24-07-05", "amount": "3" },
        { "date": "24-06-05", "amount": "3" },
    ]
    return (
        <div className='main'>
            <div className='main-container'>
                <div className='paymentHistory-header'>
                    <div className='paymentHistory-header-itme active'>홍 길 동</div>
                </div>
                <div className='paymentHistory-body'>
                    {
                        arr.map(function (a, i) {
                            return (
                                <PaymentHistoryItem key={i} item={a} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function PaymentHistoryItem({ item }) {
    return (
        <div className='paymentHistory-body-item'>
            <div>{item.date}</div>
            <div>{item.amount}만원</div>
        </div>
    )
}


export default PaymentHistory;