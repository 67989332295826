import { useEffect, useState } from 'react';
import './main.css';
import { CircleX, CircleChevronDown, CircleChevronUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function Alarm() {
    let navigate = useNavigate()
    const [isExpanded, setIsExpanded] = useState(true);
    const [cards, setCards] = useState(Array.from({ length: 3 }, (_, index) => `Card ${index + 1}`));

    const handleRemoveCard = (indexToRemove) => {
        const updatedCards = cards.filter((_, index) => index !== indexToRemove);
        setCards(updatedCards);
        if (updatedCards.length === 0) {
            navigate('/map')
        }
    };

    const containerVariants = {
        hidden: { opacity: 0, height: 0 },
        visible: {
            opacity: 1,
            height: 'auto',
            transition: { duration: 0.5, ease: 'easeInOut', staggerChildren: 0.1 },
        },
        exit: { opacity: 0, height: 0, transition: { duration: 0.3 } },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: -10 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        exit: { opacity: 0, y: -10, transition: { duration: 0.3 } },
    };

    useEffect(() => {
        if (!isExpanded) {
            navigate('/map')
        }
    }, [isExpanded])

    return (
        <div className='main'>
            <div className='main-container'>
                <div className='alarm-container' onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ?
                        <CircleChevronDown />
                        : <CircleChevronUp />
                    }
                </div>
                <AnimatePresence>
                    {isExpanded && (
                        <motion.div
                            variants={containerVariants}
                            initial="visible"
                            animate="visible"
                            exit="exit"
                            style={{ overflow: 'hidden' }}
                        >
                            {cards.map((card, index) => (
                                <motion.div
                                    key={index}
                                    variants={cardVariants}
                                    className='alarm-box'
                                >
                                    <span>{card}</span>
                                    <div
                                        onClick={() => handleRemoveCard(index)}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            border: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <CircleX />
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}

export default Alarm;
