import './nav.css'

function Footer() {
    return (
        <div className='footer'>
            <img src='/img/logo_light.png' style={{ width: "100px" }} />
        </div>
    )
}

export default Footer;