import { useNavigate } from 'react-router-dom';
import './main.css'
import { SyncLoader } from "react-spinners";
import { useEffect, useState } from 'react';

function Payment() {
    let navigate = useNavigate();
    const [splash, setSplash] = useState(false)

    const onClickPayment = () => {
        if (window.confirm("결제하시겠습니까?")) {
            setSplash(true)
            setTimeout(() => {
                navigate('/alarm')
            }, 1500)
        }
    }

    useEffect(() => {
        let obj = localStorage.getItem('payment');
        if (obj) navigate('/alarm');
    }, []);


    return (
        <div className='main'>
            <div className='main-container'>
                <img src='/img/다날.png' />
                <div className='payment' onClick={() => onClickPayment()}>결제하기</div>
            </div>
            {
                splash ?
                    <div className='splash'>
                        <SyncLoader
                            color="#003577"
                            size={15}
                            cssOverride={{ borderWidth: "4px", marginBottom: "30px" }}
                            speedMultiplier={0.8}
                        />
                    </div>
                    :
                    ""
            }
        </div>
    )
}

export default Payment;