import { useEffect, useState } from 'react';
import './main.css'
import { useNavigate } from 'react-router-dom';
import Message from '../Modal/message';
import { SyncLoader } from "react-spinners";

function Login() {
    let navigate = useNavigate()

    const [id, setId] = useState("")
    const [otp, setOTP] = useState("")
    const [state, setState] = useState(0)
    const [message, setMessage] = useState({ code: 0, msg: "" })
    const [splash, setSplash] = useState(false)

    useEffect(() => {
        let obj = localStorage.getItem("login")
        if (obj) {
            navigate(`/main`)
        }
    }, [])

    const onChangeId = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        if (onlyNumber.length <= 12) setId(onlyNumber)
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        if (onlyNumber.length <= 6) setOTP(onlyNumber)
        if (onlyNumber.length == 3) onSubmitLogin(id, onlyNumber)
    }

    const onSubmitOTP = () => {
        // if (id == "01012341234") {
        //     setMessage({ code: 0, msg: "인증번호 전송 성공" })
        //     setState(1)
        // }
        // else {
        //     setMessage({ code: 1, msg: "휴대폰번호를 다시 입력해주세요." })
        // }
        setState(1)
    }

    const onSubmitLogin = (id, onlyNumber) => {
        setSplash(true)
        setTimeout(() => {
            navigate('/main')
        }, 1500)

        // if (onlyNumber == "123") {
        //     localStorage.setItem('login', true)
        //     navigate('/alarm')
        // }
        // else {
        //     setMessage({ code: 1, msg: "인증번호를 다시 입력해주세요." })
        // }
    }

    return (
        <div className='main'>
            <Message message={message} />
            <div className='main-container'>
                <div className='main-login-box'>
                    {/* <img src='/img/logo_light.png' style={{ width: "50%", marginBottom: "30px" }} /> */}
                    <div className='login-box'>
                        <div className='login-box-text' style={{ color: state === 1 ? "gray" : "" }}>휴대폰번호</div>
                        <div className='login-box-flex'>
                            <input type='number' className='login-box-input' maxLength={12} value={id} onChange={onChangeId} disabled={state === 1 ? true : false} />
                            {
                                state === 0
                                    ?
                                    <div className='login-input-btn' onClick={() => onSubmitOTP()}>인증번호 전송</div>
                                    :
                                    <div className='login-input-btn' onClick={() => onSubmitOTP()}>재전송</div>
                            }
                        </div>
                    </div>
                    {
                        state === 1
                            ?
                            <div className='login-box'>
                                <div className='login-box-text'>인증번호</div>
                                <input type='number' className='login-box-input' maxLength={6} value={otp} onChange={onChangeOTP} />
                            </div>
                            :
                            ""
                    }
                    {/* {
                        state === 0
                            ?
                            <div className='login-btn' style={{ marginTop: "20px" }} onClick={() => onSubmitOTP()}>인증번호 전송</div>
                            :
                            <div className='login-btn' style={{ marginTop: "20px" }} onClick={() => onSubmitLogin()}>로그인</div>
                    } */}
                </div>
            </div>
            {
                splash ?
                    <div className='splash'>
                        <SyncLoader
                            color="#003577"
                            size={15}
                            cssOverride={{ borderWidth: "4px", marginBottom: "30px" }}
                            speedMultiplier={0.8}
                        />
                    </div>
                    :
                    ""
            }
        </div>
    )
}

export default Login;