// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buy-modal {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    min-width: 300px;
    max-width: calc(100% - 40px);
    /* height: 60px; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 13px;
    padding: 15px 15px;
    z-index: 100;
}

.buy-modal.dark {
    color: #121212;
}

.buy-modal.copy {
    border: 1px solid rgba(0, 9, 3, .08);
    background-color: #fff;
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    animation: Modal_opacityIn .4s ease-in-out;
}

.buy-modal.copy.dark {
    border: 1px solid #cccccc16;
    background-color: #2b2c37;
    color: #fff;
    animation: Modal_opacityIn .4s ease-in-out;
}

@keyframes Modal_opacityIn {
    from {
        opacity: 0;
        transform: translate(-50%, -20px);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0px);
    }
}`, "",{"version":3,"sources":["webpack://./src/Modal/modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;IAChB,4BAA4B;IAC5B,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,kEAAkE;IAClE,0CAA0C;AAC9C;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,WAAW;IACX,0CAA0C;AAC9C;;AAEA;IACI;QACI,UAAU;QACV,iCAAiC;IACrC;;IAEA;QACI,UAAU;QACV,+BAA+B;IACnC;AACJ","sourcesContent":[".buy-modal {\r\n    position: fixed;\r\n    top: 20px;\r\n    left: 50%;\r\n    transform: translateX(-50%);\r\n    width: auto;\r\n    min-width: 300px;\r\n    max-width: calc(100% - 40px);\r\n    /* height: 60px; */\r\n    border-radius: 5px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap: 10px;\r\n    font-size: 13px;\r\n    padding: 15px 15px;\r\n    z-index: 100;\r\n}\r\n\r\n.buy-modal.dark {\r\n    color: #121212;\r\n}\r\n\r\n.buy-modal.copy {\r\n    border: 1px solid rgba(0, 9, 3, .08);\r\n    background-color: #fff;\r\n    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;\r\n    animation: Modal_opacityIn .4s ease-in-out;\r\n}\r\n\r\n.buy-modal.copy.dark {\r\n    border: 1px solid #cccccc16;\r\n    background-color: #2b2c37;\r\n    color: #fff;\r\n    animation: Modal_opacityIn .4s ease-in-out;\r\n}\r\n\r\n@keyframes Modal_opacityIn {\r\n    from {\r\n        opacity: 0;\r\n        transform: translate(-50%, -20px);\r\n    }\r\n\r\n    to {\r\n        opacity: 1;\r\n        transform: translate(-50%, 0px);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
