// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-background {
  position: fixed;
  top: 0;
  left: 0;
  /* background-position: 100% 0; */
}

.App-background.animation {
  animation: moveImage 3s linear forwards;
}

@keyframes moveImage {
    0% {
        background-position: 0% 0;
    }

    100% {
        background-position: 100% 0;
    }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
          user-select: none;
}

body {
  /* overscroll-behavior: none; */
  /* 터치 시 화면 끌림 방지 */
  /* -webkit-overflow-scrolling: touch; */
  /* iOS 부드러운 스크롤 */
  /* touch-action: none; */
  /* 터치 동작 차단 */
}

*::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,iCAAiC;AACnC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;IACI;QACI,yBAAyB;IAC7B;;IAEA;QACI,2BAA2B;IAC/B;AACJ;;AAEA;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;EACT,aAAa;EACb,wCAAwC;EACxC,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,uCAAuC;EACvC,iBAAiB;EACjB,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  /* background-position: 100% 0; */\n}\n\n.App-background.animation {\n  animation: moveImage 3s linear forwards;\n}\n\n@keyframes moveImage {\n    0% {\n        background-position: 0% 0;\n    }\n\n    100% {\n        background-position: 100% 0;\n    }\n}\n\n* {\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  outline: none;\n  -webkit-tap-highlight-color: transparent;\n  user-select: none;\n}\n\nbody {\n  /* overscroll-behavior: none; */\n  /* 터치 시 화면 끌림 방지 */\n  /* -webkit-overflow-scrolling: touch; */\n  /* iOS 부드러운 스크롤 */\n  /* touch-action: none; */\n  /* 터치 동작 차단 */\n}\n\n*::-webkit-scrollbar {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
